import React from 'react';
import './App.scss';
import Slideshow from './components/Slideshow/Slideshow';
import Logo from './assets/images/brt-logo.png';

function App() {

  return (
    <div>
      <Slideshow />
      <img src={Logo} className="brt-logo" alt="BRT Logo" />
    </div>
  );
}

export default App;